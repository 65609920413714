import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { viewProductBreads } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import Bloomer from '../images/bloomer.png';
import Sourdough from '../images/sourdough.png';
import FrenchBread from '../images/french-bread.png';
import BraidedChallahBread from '../images/braided-challa-loaf.png';
import PestoBread from '../images/pesto-bread.png';
import MilkBread from '../images/milk-bread.png';
import CinnamonRaisinBread from '../images/cinnamon-raisin-bread.png';
import PainDeCampagne from '../images/pain-de-campagne.png';
import Foccacia from '../images/foccacia.png';
import MilkBreadRolls from '../images/japanese-milk-bread-rolls.png';
import AleRolls from '../images/ale-roll.png';
import RaspberryBread from '../images/raspberry-white-chocolate.png';
import Boule from '../images/boule.png';

function ProductBreads() {
    const enabled = useRecoilValue(viewProductBreads);

    const handleClick = (e, param) => {
        var body = "";
        var subject = "";

        switch(param) {
            case 'bloomer':
                subject = "Bleu Fox Bakery Online Order - Bloomer";
                body = "Thank you for your interest in the Bloomer. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'sourdough':
                subject = "Bleu Fox Bakery Online Order - Sourdough";
                body = "Thank you for your interest in the Sourdough. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'frenchBread':
                subject = "Bleu Fox Bakery Online Order - French Bread";
                body = "Thank you for your interest in the French Bread. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'boule':
                subject = "Bleu Fox Bakery Online Order - The Crusty Chew";
                body = "Thank you for your interest in The Crusty Chew. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'braidedChallahBread':
                subject = "Bleu Fox Bakery Online Order - Braided Challah Bread";
                body = "Thank you for your interest in the Braided Challah Bread. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'pestoBread':
                subject = "Bleu Fox Bakery Online Order - Pesto Bread";
                body = "Thank you for your interest in the Pesto Bread. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'milkBread':
                subject = "Bleu Fox Bakery Online Order - Milk Bread";
                body = "Thank you for your interest in the Milk Bread. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'cinnamonRaisinBread':
                subject = "Bleu Fox Bakery Online Order - Cinnamon Raisin Bread";
                body = "Thank you for your interest in the Cinnamon Raisin Bread. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'painDeCampagne':
                subject = "Bleu Fox Bakery Online Order - Pain De Campagne";
                body = "Thank you for your interest in the Pain De Campagne. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'theCrustyChew':
                subject = "Bleu Fox Bakery Online Order - The Crusty Chew";
                body = "Thank you for your interest in The Crusty Chew. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'focaccia':
                subject = "Bleu Fox Bakery Online Order - Focaccia";
                body = "Thank you for your interest in the Focaccia. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'raspberryBread':
                subject = "Bleu Fox Bakery Online Order - Raspberry White Chocolate Bread";
                body = "Thank you for your interest in the Raspberry White Chocolate Bread. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'milkBreadRolls':
                subject = "Bleu Fox Bakery Online Order - Japanese Milk Bread Rolls";
                body = "Thank you for your interest in the Japanese Milk Bread Rolls. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'aleRolls':
                subject = "Bleu Fox Bakery Online Order - Ale Rolls";
                body = "Thank you for your interest in the Ale Rolls. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            default:
                subject = "Bleu Fox Bakery Online Order - Products";
                body = "Thank you for your interest in our offerings. Please indicate the item(s) and quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
        }

        var email="sales@bleufoxbakery.com";
        var mailto_link='mailto:'+email+'?subject='+subject+'&body='+body;
        window.open(mailto_link,'_self');
    };

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Sourdough}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Sourdough
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience the delight of a traditional Sourdough loaf, featuring a beautiful deep brown crust with a wonderfully chewy texture, complemented by a light, partially airy crumb with a mild tang. The bread is crafted through a lengthy rising and slow proofing process, ensuring that every bite is packed with flavor. A great choice for an amazing deli sandwich, grilled cheese or toast with jam!</p>
                                        <p>Ingredients: Flour, Water, Salt, Olive Oil, Semolina and Malted Barley Flour.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'sourdough');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Bloomer}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Bloomer
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our Bloomer loaf is packed with a ton of flavor and a very versatile white bread. The bread has a crispy, slightly chewy crust with a soft interior. Our favorite for sandwiches. Also great toasted and spread with some herb butter for a kicked-up garlic toast.</p>
                                        <p>Ingredients: Flour, Water, Olive Oil, Salt, Yeast and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'bloomer');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PainDeCampagne}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Pain de Campagne
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience the joy of our Pain de Campagne, which boasts a crisp crust and lovely crumb that’s perfect for crafting sandwiches. The addition of rye flour combined with an extended rising time imparts a remarkable flavor and texture to this bread. This is a wonderfully flexible bread that you will surely enjoy.</p>
                                        <p>Ingredients: Flour, Water, Rye Flour, Salt, Yeast and Olive Oil.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'painDeCampagne');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={FrenchBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        French Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our French bread boasts a delightful flavor, featuring a gorgeous golden crust. The inside is luxuriously soft and fluffy. This bread is incredibly versatile, making it ideal for various meals. We love using it for garlic toast, crafting PB&J sliders, or turning leftovers into scrumptious French toast or amazing croutons.</p>
                                        <p>Ingredients: Flour, Water, Sugar, Olive Oil, Salt, Yeast and Malt Barley Flour.</p>
                                        <p>Price: $5 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'frenchBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Boule}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Boule
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This artisan loaf is packed full of flavor ( and holes, lots of holes ). This naturally-leavened round loaf has a moist, soft and chewy interior with a dark brown crusty exterior. A long rise provides a rich, unforgettable tasting loaf. Great for a robust sandwich, amazing as toast, incredible croutons and lots more. Store in a bread bag for best results, and use within a few days.</p>
                                        <p>Ingredients: Flour, Water, Salt and Yeast.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'boule');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BraidedChallahBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Braided Challah Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our Braided Challah Bread is very flavorful and soft. Try it with PB&J for a nostalgic sandwich, or cut into thick slices and toast it for a superb bun. On day two or three, it makes amazing french toast.</p>
                                        <p>Made without any preservatives or artificial ingredients. Baked to order for the freshest possible loaf. Store in a bread bag for best results and use within a few days. Best sliced with a bread knife.</p>
                                        <p>Ingredients: Flour, Water, Eggs, Milk, Butter, Sugar, Olive Oil, Yeast, Salt and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'braidedChallahBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PestoBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Pesto Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A beautiful bread infused with Basil Pesto. This bread has a crispy, slightly chewy crust with a soft, fragrant interior. Hints of Basil, Garlic, and Parmesan throughout create a beautifully balanced flavor. Perfect for sandwiches, or a sublime base for Avocado Toast with Egg.</p>
                                        <p>Ingredients: Flour, Water, Prepared Pesto, Olive Oil, Salt, Yeast, and Malted Barley Flour.</p>
                                        <p>Price: $7 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'pestoBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AleRolls}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Ale Rolls
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Hearty and flavorful, these robust bread rolls deserve a place at the table alongside your favorite meal. Made with high quality ale and baked to perfection, these are sure to become your new favorite rolls. We like them with lots of butter or we often use them as sliders for a deli meat sandwich.</p>
                                        <p>Ingredients: Flour, Ale, Whole Wheat Flour, Butter, Yeast, Salt, Sunflower Oil and Olive Oil.</p>
                                        <p>Price: $6 USD (7 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'aleRolls');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MilkBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Milk Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A centuries-old traditional ingredient in baking bread, milk creates a soft, light texture and a delicately sweet flavor. Square slices and all-around crust make perfect size sandwiches and is miles above store-bought loaves. Slice with a serrated knife.</p>
                                        <p>Ingredients: Flour, Milk, Butter, Sugar, Yeast, Salt, Olive Oil, Malted Barley Flour.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'milkBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={CinnamonRaisinBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Cinnamon Raisin Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This cinnamon raisin bread is incredibly flavorful with warm cinnamon in the background, packed with raisins throughout and a touch of mild sweetness giving the bread a beautiful balance.</p>
                                        <p>Can be enjoyed on it's own, as the basis for an amazing PB&J or toasted with butter, that's our favorite way to enjoy this delicious bread.</p>
                                        <p>Ingredients: Flour, Milk, Raisins, Water, Brown Sugar, Sugar, Butter, Salt, Cinnamon, Yeast and Olive Oil.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'cinnamonRaisinBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Foccacia}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Focaccia with Oregano
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Try our insanely delicious, freshly baked Italian Herb Focaccia! A fluffy, shallow, aromatic bread that is baked to perfection and hit with a light sprinkle of flaky salt. Perfect for dipping into sauce or pesto, or slice in half for a sandwich upgrade! Makes a tasty accompaniment to a salad or soup.</p>
                                        <p>Ingredients: Flour, Water, Olive Oil, Yeast, Salt, Oregano, Semolina and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'focacia');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MilkBreadRolls}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Japanese Milk Bread Rolls
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Also referred to as Hokkaido, our milk bread rolls are incredibly tender, soft, and airy. The milk creates a light, delicately sweet flavor. These are perfect for tear-and-share and make a great accompaniment to your family meal.</p>
                                        <p>Ingredients: Flour, Milk, Butter, Egg, Sugar, Water, Olive Oil, Dry Milk, Yeast, Salt and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (10 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'milkBreadRolls');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={RaspberryBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Raspberry White Chocolate Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This bread will knock your socks off! We always make the bread with fresh raspberries ( seeds are removed for a more enjoyable eating experience ) and a generous scattering of white chocolate throughout. Looks and smells amazing, and has a wonderfully soft texture and flavor. The bread has a slight chew on the crust with the interior boasting a lightly sweet and fruity flavor, it's almost dessert but can really be eaten anytime. We love to eat this for breakfast or with a nice cup of tea. Also makes a phenomenal PB&J.</p>
                                        <p>Ingredients: Flour, Water, White Chocolate, Fresh Raspberries, Olive Oil, Sugar, Yeast, Salt and Malted Barley Flour.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'raspberryBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default ProductBreads;